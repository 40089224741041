<template>
  <div class="row">
    <div class="col-6">
      <div v-if="usingProducingOfficeDropdown()" class="form-group">
        <label for="selProducingOffice">Producing Office *</label>
        <v-select
            id="selProducingOffice"
            aria-describedby="spnProducingOfficeError"
            :class="{ 'is-invalid': classTypeHasError }"
            label="regional_company_name"
            :options="$store.getters.producingOffices"
            :disabled="$store.getters.producingOffices.length===0"
            :value="$store.getters.selectedProducingOffice"
            :clearable="true"
            placeholder="Select a Producing Office"
            @input="office => changeProducingOffice(office)"
        ></v-select>
        <span
            id="spnProducingOfficeError"
            class="invalid-feedback"
            v-if="producingOfficeHasError"
        >{{ producingOfficeError }}
            </span>
      </div>
      <div class="form-group">
        <label for="selClassOfBusiness">Class of Business *</label>
        <v-select
            id="selClassOfBusiness"
            aria-describedby="spnPolicyClassError"
            :class="{ 'is-invalid': classTypeHasError }"
            label="policy_class_type_caption"
            :options="$store.getters.classTypes"
            :disabled="$store.getters.classTypes.length===0"
            :value="$store.getters.classType"
            :clearable="true"
            placeholder="Select a Class of Business"
            @input="val => changeClassOfBusiness(val)"
        ></v-select>
        <span
            id="spnClassTypeError"
            class="invalid-feedback"
            v-if="classTypeHasError"
        >{{ classTypeError }}
            </span>
      </div>
      <div class="form-group" v-if="!usingSingleHeader()">
        <label for="selPolicyClass">Policy Type *</label>
        <v-select
            id="selPolicyClass"
            label="policy_class_caption"
            :class="{ 'is-invalid': policyClassHasError }"
            :options="$store.getters.policyClasses"
            :value="$store.getters.policyClass"
            :clearable="true"
            aria-describedby="spnPolicyClassError"
            placeholder="Select a Policy Type"
            :disabled="
                $store.getters.classType === null ||
                  $store.getters.policyClasses.length === 0
              "
            @input="policyClassSelected"
        ></v-select>
        <span
            id="spnPolicyClassError"
            class="invalid-feedback"
            v-if="policyClassHasError"
        >{{ policyClassError }}
            </span>
      </div>
      <div class="form-group" v-if="!usingSingleDetailsPane() && usingSingleHeader()">
        <label for="inpCountriesList">
          Countries Selected * (Pick countries from the lists in the section
          below)
        </label>
        <br />
        <!--          TODO: Convert to a component    -->
        <div
            class="card bg-light"
            :class="{
                'is-invalid': countriesHasError,
                'countries-invalid': countriesHasError
              }"
            style="margin-bottom: 0;"
        >
          <div
              class="card-body"
              style="padding: 5px 15px;min-height: 34px;"
          >
            <a
                v-for="country in $store.getters.getSelectedCountries"
                :key="country.country_id"
                class="badge badge-primary text-white cursor-pointer"
                style="margin-right: 3px;"
                @click="removeCountry(country.country_id)"
            >
              <i class="fa fa-times fa-sm"></i>&nbsp;
              {{ country.country_name }}
            </a>
          </div>
        </div>
        <span
            id="spnCountriesError"
            class="invalid-feedback"
            v-if="countriesHasError"
        >{{ countriesError }}
            </span>
      </div>
    </div>

    <div class="col-6" v-if="usingSingleHeader()">
      <div class="form-group">
        <label for="selPolicyClass">Policy Type *</label>
        <v-select
            id="selPolicyClass"
            label="policy_class_caption"
            :class="{ 'is-invalid': policyClassHasError }"
            :options="$store.getters.policyClasses"
            :value="$store.getters.policyClass"
            :clearable="true"
            aria-describedby="spnPolicyClassError"
            placeholder="Select a Policy Type"
            :disabled="
                $store.getters.classType === null ||
                  $store.getters.policyClasses.length === 0
              "
            @input="policyClassSelected"
        ></v-select>
        <span
            id="spnPolicyClassError"
            class="invalid-feedback"
            v-if="policyClassHasError"
        >{{ policyClassError }}
            </span>
      </div>
    </div>

    <div class="col-6">
      <div class="form-group" v-if="!usingSingleDetailsPane() && !usingSingleHeader()">
        <label for="inpCountriesList">
          Countries Selected * (Pick countries from the lists in the section
          below)
        </label>
        <br />
        <!--          TODO: Convert to a component    -->
        <div
            class="card bg-light"
            :class="{
                'is-invalid': countriesHasError,
                'countries-invalid': countriesHasError
              }"
            style="margin-bottom: 0;"
        >
          <div
              class="card-body"
              style="padding: 5px 15px;min-height: 34px;"
          >
            <a
                v-for="country in $store.getters.getSelectedCountries"
                :key="country.country_id"
                class="badge badge-primary text-white cursor-pointer"
                style="margin-right: 3px;"
                @click="removeCountry(country.country_id)"
            >
              <i class="fa fa-times fa-sm"></i>&nbsp;
              {{ country.country_name }}
            </a>
          </div>
        </div>
        <span
            id="spnCountriesError"
            class="invalid-feedback"
            v-if="countriesHasError"
        >{{ countriesError }}
            </span>
      </div>

      <div class="form-group" v-if="usingPolicyLimit()">
        <label for="inpPolicyLimit">Policy Limit (Optional)</label>
        <b-input-group>
          <template v-slot:prepend>
            <b-dropdown
                :text="$store.getters.selectedCurrencyCode"
                variant="secondary"
            >
              <b-dropdown-form>
                <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="Filter"
                    maxlength="3"
                    ref="currencyCodeFilterInput"
                    @input="
                        $store.commit('setCurrencyCodeFilter', {
                          filter: $event.target.value
                        })
                      "
                />
              </b-dropdown-form>
              <b-dropdown-item-button
                  v-for="currency of $store.getters.currencyCodes"
                  :key="currency"
                  @click="
                      $store.dispatch('setCurrencyCode', { code: currency })
                    "
              >
                {{ currency }}
              </b-dropdown-item-button>
            </b-dropdown>
          </template>
          <currency-formatted-input
              :value="$store.getters.getPolicyLimit"
              @input="policyLimitChanged"
              placeholder="Policy Limit"
              id="inpPolicyLimit"
          ></currency-formatted-input>
        </b-input-group>
      </div>
    </div>
  </div>
</template>

<script>
import FeaturesMixin from "@/mixins/features.mixin";
import CurrencyFormattedInput from "../components/CurrencyFormattedInput";
import DefaultsService from "@/services/defaults.service";
// import axios from "axios";
// import * as Sentry from "@sentry/vue";

export default {
  name: "InquirySingleDetailsPane",
  components: {
    CurrencyFormattedInput,
  },
  mixins: [FeaturesMixin],
  props: {
      producingOfficeHasError: {
        type: Boolean,
        default: false,
      },
      producingOfficeError: {
        type: String, 
        default: ""
      },
      classTypeHasError: {
        type: Boolean,
        default: false,
      },
      classTypeError: {
        type: String, 
        default: ""
      },
      policyClassHasError: {
        type: Boolean,
        default: false,
      },
      policyClassError: {
        type: String, 
        default: "",
      },
      countriesHasError: {
        type: Boolean,
        default: false,
      },
      countriesError: {
        type: String, 
        default: "",
      },
  },
  data: () => {
    return {
      emailAddress: "",
      clientName: "",
      clientId: "",
      inceptionDate: new Date(),
      appliedCoverDataToCountries: false,
      fieldsChangedWithoutReload: false,
      maxPolicyLimit: 10000000, // Ten million (USD)
      partnersList: [],
    };
  },
  methods: {
    clearResultsGoToCountrySelect() {
      this.fieldsChangedWithoutReload = true;
      if (this.$router.history.current.name !== "Country Select") {
        this.$router.push({ name: "Country Select" });
        document
            .getElementById("topClearSelectionsBtn")
            .scrollIntoView({ behavior: "smooth" });
      }
    },
    removeCountry(id) {
      this.clearResultsGoToCountrySelect();
      this.$store.dispatch("selectDeselectCountry", {
        countryId: id,
        checked: false
      });
    },
    policyClassSelected(val) {
      this.policyClassHasError = false;
      this.policyClassError = "";
      this.clearResultsGoToCountrySelect();
      this.$store.dispatch("changePolicyClass", {policyClass: val});
    },
    policyLimitChanged(val) {
      this.clearResultsGoToCountrySelect();

      // Restrict to < 10 million USD...
      const rate = this.$store.getters.selectedCurrencyRate;
      if (rate == 0) {
        console.error("Selected rate reported as zero... Unable to continue");
        return;
      }
      const policyLimitUSD = val / rate;
      if (this.usingPolicyLimitMaxLimit() && policyLimitUSD > this.maxPolicyLimit) {
        val = rate * this.maxPolicyLimit;
        const extra =
          this.$store.getters.selectedCurrencyCode !== "USD"
            ? " (" +
              this.$options.filters.formatCurrency(val) +
              " " +
              this.$store.getters.selectedCurrencyCode +
              ")"
            : "";
        this.$toasted.info(
          "The policy limit must be <= " +
            this.$options.filters.formatCurrency(this.maxPolicyLimit) +
            " USD" +
            extra
        );
      }

      this.$store.dispatch("changePolicyLimit", {
        policyLimit: val
      });
    },
    changeClassOfBusiness(val) {
      this.classTypeHasError = false;
      this.classTypeError = "";
      this.clearResultsGoToCountrySelect();
      this.$store.dispatch("changeClassOfBusiness", { classType: val });
    },
    changeProducingOffice(office) {
      let officeId = 0;
      if (office) {
        officeId = office.company_id;
      }
      DefaultsService.selectProducingOffice(officeId);
    },
  }
}
</script>
